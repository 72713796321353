import flatpickr from "flatpickr"
import { Spanish, Portuguese} from "flatpickr/dist/l10n"

flatpickr(".datepicker", {
  altInput: true,
  "locale": Spanish
});


// Function to get the current time in Brazil (São Paulo, UTC-3)
function getBrazilTime() {
  // Brazil Standard Time (GMT-3)
  return new Date().toLocaleString("en-US", { timeZone: "America/Sao_Paulo" });
}

// Calculate the minDate based on the current time in Brazil
function calculateMinDate() {
  let brazilTime = new Date(getBrazilTime());
  let dayOfWeek = brazilTime.getDay();
  let hour = brazilTime.getHours();

  let minDateIncr = 2; // Default increment is 2 days
  if (dayOfWeek === 6) { // Saturday
      minDateIncr = 3;
  } else if (dayOfWeek === 5 && hour >= 17) { // Friday after 17:00
      minDateIncr = 4;
  } else if (dayOfWeek === 0 || hour >= 17) { // Sunday or any day after 17:00
      minDateIncr = 2;
  } else if (hour < 17) { // Any day before 17:00
      minDateIncr = 1;
  }

  return new Date().fp_incr(minDateIncr);
}

// Initialize flatpickr with the calculated minDate
flatpickr(".datebriefpicker", {
  altInput: true,
  altFormat: "l d M y",
  minDate: calculateMinDate(),
  maxDate: new Date().fp_incr(20),
  label_html: { class: "form-label text-secondary fw-bold" },
  "locale": "pt"
});


// FlatPicker for ranges
flatpickr(".date-range", {
  mode: "range",
  dateFormat: "Y-m-d", // You can change the format as needed
  onChange: function(selectedDates, dateStr, instance) {
      console.log(selectedDates); // Selected dates array
      console.log(dateStr);       // Formatted date string
  }
});
